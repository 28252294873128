import { createStore } from "vuex"
import $store from "../store"

import authModule from './modules/auth/index'
import sidebarModule from '@/gov/store/modules/sidebar/index'
import profileModule from './modules/profile'

const store = createStore({
    actions: {
        async send(context, options) {
            const url = options.url;
            const fetchOptions = {
                headers: {
                    'Authorization': 'Bearer ' + context.rootGetters['auth/token'],
                }
            };

            if (!options.isFormData) {
                fetchOptions.headers['Content-Type'] = 'application/json;charset=utf-8';
            }

            if (options.method) {
                fetchOptions.method = options.method;
            }

            if (options.body) {
                fetchOptions.body = options.body;
            }

            const response = await fetch(url, fetchOptions);
            const responseData = await response.json();

            if (!response.ok) {
                let error = {};
                if (responseData.error === null) {
                    error.form = responseData.message;

                    if (error.form === 'Unauthenticated.') {
                        await context.dispatch('auth/CLEAR_TOKEN')
                        window.location.href = '/'
                    }

                    if (response.status === 429) {
                        const delaySeconds = Number(response.headers.get('retry-after'))
                        $store.commit('auth/SET_RETRY_DELAY', delaySeconds)
                    }

                } else {
                    error = responseData.error;
                }
                throw new Error(JSON.stringify(error));
            }

            return responseData;
        },
    },
    modules: {
        auth: authModule,
        sidebar: sidebarModule,
        profile: profileModule,
    },
});

export default store;
