function convertMenu(menu, link = '') {
    let menuItem = [];

    for (let key in menu) {
        if (menu.hasOwnProperty(key)) {
            const href = link + '/' + key.replaceAll('_', '-');
            const item = {
                title: menu[key].label,
                name: key,
                meta: menu[key].meta,
            }

            if (menu[key].submenu) {
                item.children = convertMenu(menu[key].submenu, href);
            } else {
                item.href = href;
            }

            menuItem.push(item);
        }
    }
    return menuItem;
}

export default {
    async sidebar(context) {
        const response = await context.dispatch('send', {
            url: '/api/gov/sidebar',
        });

        if (response.hasOwnProperty('status') && response.status === 'success') {
            if (response.hasOwnProperty('data')) {
                if (response.data.hasOwnProperty('header')) {
                    const header = {
                        name: response.data.header.company_name || '',
                        user: response.data.header.user_name || '',
                    };
                    if (response.data.header.company_logo) {
                        header.logo = response.data.header.company_logo;
                        header.isLogoBlob = true;
                    } else {
                        header.isLogoBlob = false;
                    }
                    context.commit('setSidebarHeader', header);
                }
                if (response.data.hasOwnProperty('menu')) {
                    const menu = convertMenu(response.data.menu, '');
                    context.commit('setMenu', menu);
                }
                if (response.data.hasOwnProperty('buttons')) {
                    const buttons = [];
                    for (let button in response.data.buttons) {
                        if (response.data.buttons.hasOwnProperty(button)) {
                            let href = '';
                            if (button === 'apply') {
                                href = `/orders/create`;
                            }
                            if (button === 'add_recycled_volumes') {
                                href = '/volumes/recycled/add';
                            }
                            buttons.push({
                                id: button,
                                href: href,
                                data: response.data.buttons[button],
                            });
                        }
                    }
                    context.commit('setSidebarButtons', buttons);
                }
            }
        }
    },
}
