import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    state() {
        return {
            header: {
                logo: '',
                isLogoBlob: false,
                user: '',
                name: '',
            },
            menu: {},
            buttons: [],
        };
    },
    mutations,
    actions,
    getters
};
