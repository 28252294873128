export default {
    setPersonalData(state, payload = {profile: {}, login_data: {}}) {
        state.profile = payload.profile
        state.loginData = payload.login_data
    },

    setPersonalDataFetchTimestamp(state) {
        state.lastProfileFetch = new Date().getTime();
    },
    setPersonalDataActiveFetchTimestamp(state) {
        state.lastProfileActiveFetch = new Date().getTime();
    },

    updatePermissions(state, payload) {
        state.permissions = { ...payload }
    }
}
