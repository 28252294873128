import { ProfileService } from "@/gov/services/profile.service";
import profile from "@/gov/api/requests/profile";

export default {
    async profile(context, payload = {}) {
        if (!payload.forceRefresh && !context.getters.shouldProfileUpdate) {
            return;
        }

        const response = await context.dispatch('send', {
            url: profile.PROFILE,
        });

        if (response.hasOwnProperty('status') && response.status === 'success') {
            if (response.hasOwnProperty('data')) {
                context.commit('setPersonalData', response.data)
            }
        }

        context.commit('setPersonalDataFetchTimestamp');

        return response
    },
    async updateActivateStatus(context, payload = {}) {
        if (!payload.forceRefresh && !context.getters.shouldProfileActiveUpdate) {
            return;
        }
        const response = await ProfileService.getPermission();
        if (response.success) {
            context.commit('updatePermissions', response.data)
            context.commit('setPersonalDataActiveFetchTimestamp');
        }
    }
}
