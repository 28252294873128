import mutations from './mutations.js';
import actions from './actions.js';
import getters from './getters.js';

export default {
    state() {
        return {
            permissions: {
                is_ativated: false,
                canCreateEmployee: false,
                canUpdateEmployee: false,
                canDeleteEmployee: false,
            },
            profile: {
                first_name: null,
                last_name: null,
                patronymic: null,
                mailing: 0,
            },
            loginData: {
                phone: null,
                phone_verified: false,
                email: null,
                password_updated_at: null,
            },
            lastProfileFetch: null,
            lastProfileActiveFetch: null,
        };
    },
    mutations,
    actions,
    getters
};
