import {createRouter, createWebHistory} from 'vue-router'
import store from '@/gov/store'

import archivesRouter from '../modules/archives/router'

const NotFound = () => import('@/_core/views/NotFound')
const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_BASE_GOV_ROUTER),
    routes: [
        {
            path: '/',
            component: () => import('@/gov/pages/IndexPage'),
            redirect: '/home',
            name: 'home',
            children: [
                {
                    path: 'home',
                    name: 'dashboard',
                    component: () => import('@/gov/pages/HomePage'),
                },

                {
                    path: 'profile',
                    name: 'profile',
                    component: () => import('@/gov/pages/profile/IndexPage'),
                },

                {
                    path: 'companies/mi',
                    component: () => import('@/gov/pages/companies/mi/IndexPage'),
                },
                {
                    path: 'companies/mi/:id',
                    component: () => import('@/gov/pages/companies/mi/DetailPage'),
                    meta: { group: 'mi' },
                    props: true,
                },
                {
                    path: 'companies/mi/:id/orders/:orderId',
                    component: () => import('@/gov/pages/companies/mi/orders/DetailPage'),
                    meta: { group: 'mi' },
                    props: true,
                },
                {
                    path: 'companies/mi/:id/:groupId/:groupType/:groupYear',
                    component: () => import('@/gov/pages/companies/mi/performers/IndexPage'),
                    meta: { group: 'mi' },
                    props: true,
                },
                {
                    path: 'companies/mi/:id/:groupId/:groupType/:groupYear/:performerId',
                    component: () => import('@/gov/pages/companies/mi/performers/DetailPage'),
                    meta: { group: 'mi' },
                    props: true,
                },
                // {
                //     path: 'companies/mi/:id/responses/:responseId',
                //     component: () => import('@/gov/pages/companies/mi/responses/DetailPage'),
                //     meta: { group: 'mi' },
                //     props: true,
                // },
                {
                    path: 'companies/recycler',
                    name: 'recycler-company',
                    component: () => import('@/gov/pages/companies/recycler/IndexPage'),
                },
                {
                    path: 'companies/recycler/volumes/received/:id',
                    component: () => import('@/gov/pages/companies/recycler/volumes/received/DetailPage'),
                    props: true,
                },
                {
                    path: 'companies/recycler/volumes/recycled/:id',
                    component: () => import('@/gov/pages/companies/recycler/volumes/recycled/DetailPage'),
                    props: true,
                },
                {
                    path: 'companies/recycler/:id',
                    redirect: (to) => 'recycler/' + to.params.id + '/about',
                    component: () => import('@/gov/pages/companies/recycler/DetailPage'),
                    meta: { group: 'recycler-volumes' },
                    props: true,
                    children: [
                        { path: 'about', name: 'company-about', component: () => import('@/gov/pages/companies/recycler/AboutPage'), meta: { title: 'О компании' }, props: true},
                    ],
                },
                {
                    path: 'plants/:id',
                    redirect: (to) => '/plants/' + to.params.id + '/general',
                    component: () => import('@/gov/pages/plants/DetailPage'),
                    meta: { group: 'recycler-volumes' },
                    props: true,
                    children: [
                        {
                            path: 'general',
                            component: () => import('@/gov/pages/plants/general/IndexPage'),
                            meta: { group: 'recycler-info' },
                            props: true,
                        },
                        {
                            path: 'capabilities',
                            component: () => import('@/gov/pages/plants/capabilities/IndexPage'),
                            meta: { group: 'recycler-info' },
                            props: true,
                        }
                    ]
                },
                {
                    path: 'companies/recycler/:id/responses/:responseId',
                    component: () => import('@/gov/pages/companies/recycler/responses/DetailPage'),
                    meta: { group: 'recycler-volumes' },
                    props: true,
                },

                {
                    path: 'orders',
                    component: () => import('@/gov/pages/orders/IndexPage'),
                },

                {
                    path: 'employees',
                    name: 'employees',
                    component: () => import('@/gov/pages/employees/IndexPage'),
                },
                {
                    path: 'employees/create',
                    name: 'create-employee',
                    component: () => import('@/gov/pages/employees/CreatePage'),
                },
                {
                    path: 'employees/:id',
                    props: true,
                    component: () => import('@/gov/pages/employees/DetailPage'),
                },

                ...archivesRouter,
            ],
            meta: { requiresAuth: true, requiresActive: true, },
        },
        {
            path: '/auth',
            component: () => import('@/gov/pages/AuthPage'),
            redirect: '/auth/signin',
            children: [
                {
                    path: 'signin',
                    name: 'signin',
                    component: () => import('@/gov/pages/auth/signin/SigninPage'),
                    meta: { title: 'Авторизация', requiresUnauth: true },
                },
                {
                    path: 'sms-confirm',
                    component: () => import('@/gov/pages/auth/sms-confirm/SmsConfirmPage'),
                    meta: { title: 'Подтверждение номера', requiresUnauth: true },
                },
                {
                    path: 'activate',
                    component: () => import('@/gov/pages/auth/activate/ActivatePage'),
                    meta: { title: 'Смена пароля', requiresAuth: true },
                },
                {
                    path: 'recovery',
                    name: 'recovery',
                    component: () => import('@/gov/pages/auth/recovery/RecoveryPage'),
                    meta: { title: 'Восстановление пароля' }
                },
                {
                    path: 'recovery/password/:token',
                    component: () => import('@/gov/pages/auth/recovery/password/DetailPage'),
                    meta: { title: 'Сброс пароля' },
                    props: true
                },
                {
                    path: 'recovery-submitted',
                    name: 'recovery-submitted',
                    component: () => import('@/gov/pages/auth/recovery/RecoverySubmittedPage'),
                    meta: { title: 'Восстановление пароля' }
                },
            ],
        },
        { path: '/:notFound(.*)', component: NotFound },
    ],
    linkActiveClass: 'is-active',
    linkExactActiveClass: 'is-exact-active',
});

router.beforeEach(async function(to, from, next) {
    if (to.path !== from.path) {
        window.document.title = 'SES' + (to.meta.title ? (' | ' + to.meta.title) : '')

        setTimeout(function () {
            window.scrollTo({
                top: 0,
                behavior: 'instant',
            });
        }, 0);
    }

    if (store.getters['auth/isAuthenticated'] && to.meta.requiresActive && !store.getters.isProfileActive) {
        await store.dispatch('updateActivateStatus')
    }

    if (to.meta.requiresAuth && !store.getters['auth/isAuthenticated']) {
        next('/auth/signin')
    } else if (to.meta.requiresActive && !store.getters.isProfileActive) {
        next('/auth/activate')
    } else if (to.meta.requiresUnauth && store.getters['auth/isAuthenticated']) {
        next('/home')
    } else {
        next()
    }
});

export default router;
