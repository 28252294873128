import { createApp } from 'vue';

import router from './router';
import store from './store';

import App from './App';

import '@/_core/assets/sass/app.scss'
import '@/_core/assets/fonts/all.css'

const app = createApp(App);
app.use(router);
app.use(store);
app.mount('#app');
