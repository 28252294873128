const IndexPage = () => import('./views/Index')

export default [
    {
        path: 'archives',
        name: 'archives',
        component: IndexPage,
        meta: { title: 'Реестр отчётных документов' }
    }
]
