import axios from 'axios'

import { Http } from './http.init'
import { ResponseWrapper, ErrorWrapper } from './util'
import $store from '../store'
import $router from '../router'

import {API_URL} from "@/gov/config/config"
import auth from '@/cabinet/api/requests/auth'

let bearer = ''
let isDisabled = false

export class AuthService {

    static async makeLogin ({ email, password }) {
        try {
            const response = await axios.post(
                `${API_URL}/auth/login`,
                { email, password },
            )
            _setAuthData({
                accessToken: response.data.data.access_token,
                key: response.data.data.key,
                phone: response.data.data.phone,
                seconds: response.data.data.seconds_before_resend,
            })
            return new ResponseWrapper(response, response.data.data)
        } catch (error) {
            const errorWrapper = new ErrorWrapper(error)
            if (errorWrapper.error && errorWrapper.error.double_penetration) {
                _setAuthData({
                    key: errorWrapper.error.key,
                    phone: errorWrapper.error.phone,
                    seconds: errorWrapper.error.seconds_before_resend,
                })
            }

            return errorWrapper
        }
    }

    static async makeLogout () {
        try {
            const response = await new Http().get('/auth/logout')
            _resetAuthData()
            $router.push({ name: 'signin' }).catch(() => {})
            return new ResponseWrapper(response, response.data.data)
        } catch (error) {
            throw new ErrorWrapper(error)
        }
    }

    static async makeLoginConfirm ({ key, confirmation_code }) {
        try {
            const response = await axios.post(
                `${API_URL}/auth/login/confirm`,
                { key, confirmation_code }
            )
            _setAuthData({
                accessToken: response.data.data.access_token,
            })
            return new ResponseWrapper(response, response.data.data)
        } catch (error) {
            return new ErrorWrapper(error)
        }
    }

    static async refreshCode (key) {
        try {
            const response = await axios.post(
                `${API_URL}/auth/login/resend-code`,
                {key},
            )
            _setAuthData({
                key: response.data.data.key,
                phone: response.data.data.phone,
                seconds: response.data.data.seconds_before_resend,
            })
            return new ResponseWrapper(response, response.data.data)
        } catch (error) {
            return new ErrorWrapper(error)
        }
    }

    static async activate ({password, password_confirmation}) {
        try {
            const response = await new Http().post(
                '/profile/activate',
                {password, password_confirmation},
            )
            return new ResponseWrapper(response, response.data.data)
        } catch (error) {
            return new ErrorWrapper(error)
        }
    }

    static setBearer (token) {
        bearer = 'Bearer ' + token
        localStorage.setItem('token', token)
    }

    static getBearer () {
        return bearer
    }

    static isDisabled () {
        return isDisabled
    }

    static disable () {
        isDisabled = true
    }

    static enable () {
        isDisabled = false
    }

    static async recoveryPassword (email) {
        try {
            const response = await axios.post(
                API_URL + auth.recovery.PASSWORD,
                { email },
            )
            return new ResponseWrapper(response, response.data.data)
        } catch (error) {
            return new ErrorWrapper(error)
        }
    }

    static async verifyToken (token) {
        try {
            const response = await axios.get(
                API_URL + auth.recovery.VERIFY_TOKEN.replace('{{token}}', token)
            )
            return new ResponseWrapper(response, response.data.data)
        } catch (error) {
            return new ErrorWrapper(error)
        }
    }

    static async resetPassword ({token, password, passwordConfirm}) {
        try {
            const response = await axios.post(
                API_URL + auth.recovery.RESET_PASSWORD,
                { token, password, password_confirmation: passwordConfirm },
            )
            return new ResponseWrapper(response, response.data.data)
        } catch (error) {
            return new ErrorWrapper(error)
        }
    }

    static clearAuthData() {
        _resetAuthData()
    }
}

function _resetAuthData () {
    bearer = ''
    localStorage.removeItem('token')
    $store.commit('auth/SET_AUTH_TOKEN', { accessToken: null })
}

function _setAuthData ({accessToken, key, phone, seconds}) {
    if (accessToken) {
        AuthService.setBearer(accessToken)
        $store.commit('auth/SET_AUTH_TOKEN', { accessToken })
        _removeConfirmData()
    } else {
        _setConfirmData({ key, phone, seconds })
    }
}

function _setConfirmData({ key, phone, seconds }) {
    sessionStorage.setItem('key', key)
    sessionStorage.setItem('phone', phone)
    sessionStorage.setItem('seconds', seconds)
}

function _removeConfirmData() {
    sessionStorage.removeItem('key')
    sessionStorage.removeItem('phone')
    sessionStorage.removeItem('seconds')
}
