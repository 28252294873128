export default {
    setMenu(state, payload) {
        state.menu = {
            ...payload,
        };
    },
    setSidebarHeader(state, payload) {
        state.header = {
            ...payload,
        };
    },
    setSidebarButtons(state, payload) {
        state.buttons = {
            ...payload,
        };
    },
}
