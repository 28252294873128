export default {
    getProfileData(state) {
        return state.profile;
    },
    getLoginData(state) {
        return state.loginData;
    },

    shouldProfileUpdate(state) {
        const lastFetch = state.lastProfileFetch;
        if (!lastFetch) {
            return true;
        }
        const currentTimeStamp = new Date().getTime();
        return (currentTimeStamp - lastFetch) / 1000 > 60;
    },
    shouldProfileActiveUpdate(state) {
        const lastFetch = state.lastProfileActiveFetch;
        if (!lastFetch) {
            return true;
        }
        const currentTimeStamp = new Date().getTime();
        return (currentTimeStamp - lastFetch) / 1000 > 60;
    },

    isProfileActive(state) {
        return state.permissions.isActivated
    },

    canCreateEmployee(state) {
        return state.permissions.canCreateEmployee
    },
    canUpdateEmployee(state) {
        return state.permissions.canUpdateEmployee
    },
    canDeleteEmployee(state) {
        return state.permissions.canDeleteEmployee
    },
}
