import axios from 'axios'

import { AuthService } from '@/gov/services/auth.service'
import { API_URL } from "@/gov/config/config"
import $store from '../store'

export class Http {
    constructor () {
        this.instance = axios.create({
            baseURL: API_URL,
        })

        return this.init()
    }

    init () {
        if ($store.getters['auth/retryDelay']) {
            return
        }
        this.instance.interceptors.request.use(request => {
            if (!AuthService.getBearer()) {
                AuthService.setBearer(localStorage.getItem('token'))
            }
            request.headers.Authorization = AuthService.getBearer()
            return request
        }, error => {
            return Promise.reject(error)
        });

        return this.instance
    }
}
