import { Http } from './http.init'
import { ResponseWrapper, ErrorWrapper } from './util'

export class ProfileService {
    static async getPermission() {
        try {
            const response = await new Http().get('/profile/permissions')
            return new ResponseWrapper(response, response.data.data)
        } catch (error) {
            throw new ErrorWrapper(error)
        }
    }
}