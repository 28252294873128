export default {
    namespaced: true,
    state() {
        return {
            token: null,
            retryDelay: 0,
        };
    },
    mutations: {
        SET_AUTH_TOKEN (state, payload) {
            state.token = payload.accessToken
        },
        SET_RETRY_DELAY (state, payload) {
            let seconds = Number(payload)

            if (seconds < 1) {
                state.retryDelay = 0
                return
            }

            const interval = setInterval(function () {
                if (seconds > 1) {
                    seconds -= 1
                } else {
                    seconds = 0
                    clearInterval(interval)
                }
                state.retryDelay = seconds
            }, 1000)
        },
    },
    actions: {
        TRY_LOGIN (context) {
            const accessToken = localStorage.getItem('token');

            if (accessToken && accessToken !== 'null') {
                context.commit('SET_AUTH_TOKEN', { accessToken });
            }
        },
        async signout (context) {
            await context.dispatch('send', {
                url: '/api/gov/logout',
            });

            await context.dispatch('CLEAR_TOKEN')
        },

        async CLEAR_TOKEN(context) {
            localStorage.removeItem('token');
            context.commit('SET_AUTH_TOKEN', {
                accessToken: null,
            });
        },
    },
    getters: {
        token(state) {
            return state.token;
        },
        isAuthenticated(state) {
            return !!state.token;
        },
        retryDelay(state) {
            return state.retryDelay;
        },
        didAutoSignout(state) {
            return state.didAutoSignout;
        },
    },
};
