export default {
    getMenu(state) {
        return state.menu;
    },
    getSidebarHeader(state) {
        return state.header;
    },
    getSidebarButtons(state) {
        return state.buttons;
    }
}
