export default {
    recovery: {
        PASSWORD: '/{{cabinet_type}}/auth/recovery/password',
        RESET_PASSWORD: '/{{cabinet_type}}/auth/recovery/reset-password',
        VERIFY_TOKEN: '/{{cabinet_type}}/auth/recovery/verify-token/{{token}}',
    },
    login: {
        LOGIN: '/{{cabinet_type}}/auth/login',
        CONFIRM: '/{{cabinet_type}}/auth/login/confirm',
    },
    register: {
        REGISTER: '/{{cabinet_type}}/auth/register',
        CONFIRM: '/{{cabinet_type}}/auth/register/confirm',
    },
    RESEND_CODE: '/{{cabinet_type}}/auth/login/resend-code',
    LOGOUT: '/{{cabinet_type}}/auth/logout',
    EMAIL_VERIFY: '/{{cabinet_type}}/auth/email/verify/{{token}}',
    SEND_EMAIL_VERIFY: '/{{cabinet_type}}/auth/email/verify',
    CHECK_PASSWORD: '/{{cabinet_type}}/auth/check-password',
}
