<script>
import { useStore } from 'vuex'

export default {
    setup() {
        const store = useStore();
        store.dispatch('auth/TRY_LOGIN');
    },
}
</script>

<template>
    <RouterView />
</template>
